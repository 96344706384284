<template> 

  <div class="container pt-4 mb-5">

    <div>
      
      <b-row align-v="center">

        <b-col lg="12">
 
          <div class="mt-4">

            <div class="mt-3">

              <label>Opisz dokładnie swój dzień:</label>

              <b-input-group>

                <b-form-input v-model="dzien" size="lg" ref="dzien" placeholder="np. wstaję o 5.00, potem idę do pracy, po pracy godzina siłowni, potem kolacja, Netflix i idę spać"></b-form-input>
                
              </b-input-group>
 
            </div>

            <div class="mt-3">

              <label>Napisz jaki cel chcesz osiągnąć:</label>

              <b-input-group>

                <b-form-input v-model="cel" size="lg" ref="cel" placeholder="np. chcę zarabiać 20 000 miesięcznie"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Jakie obszary w swoim życiu chcesz poprawić:</label>

              <b-input-group>

                <b-form-input v-model="obszar" size="lg" ref="obszar" placeholder="np. ilość zarabianych pieniędzy"></b-form-input>

              </b-input-group>

            </div>

          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje treść <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowana treść</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;">

              <div v-if="result" v-html="result" class="pb-3 mb-3" style="border-bottom: 1px solid #ddd;"></div>

            </div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
   
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>
 
    </div>

  </div>

</template>

<script>

  export default {
    name: 'apxc',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        result2: "",
        selectedText: '',
        query: "",
        dzien: "",
        cel: "",
        obszar: "",
        finishContent: '',
        selectedSchema: null
      }
    },
    methods: {

      generateFinish: function() {

        if(!this.dzien) return false;
        if(!this.cel) return false;
        if(!this.obszar) return false;

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "Chcę, abyś był moim osobistym doradcą do planowania codziennej rutyny w stylu ludzi sukcesu i milionerów. Podam Ci szczegóły dotyczące mojego dnia, celów życiowych, priorytetów i zainteresowań, a Twoim zadaniem będzie zaprojektowanie codziennej rutyny, która:\n" +
            "1.\tUwzględni nawyki ludzi sukcesu, takie jak poranne rytuały, planowanie dnia, czas na rozwój osobisty i zawodowy oraz zdrowie.\n" +
            "2.\tBędzie dopasowana do mojego stylu życia, możliwości czasowych i celów.\n" +
            "3.\tZawiera konkretne bloki czasowe na pracę, odpoczynek, ćwiczenia fizyczne, medytację, rozwój zawodowy i inne kluczowe obszary mojego życia.\n" +
            "Zawsze uwzględnij sugestie i nawyki milionerów, takie jak poranne afirmacje, zarządzanie czasem czy techniki produktywności. Oto mój obecny plan dnia "+this.dzien+" chcę osiągnąć cel "+this.cel+" i chce poprawić obszar "+this.obszar+".";

        query = query + "Używaj znaczników HTML zamiast markdown. Dozwolone znaczniki: Pogrubienie czyli <B></B>, i nowa linia <br/> . Zamiast znaczników nagłówków Hx użyj po prostu pogrubienia.";

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query, 'v': 'o1' }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data
 
          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');


          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowana treść"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },
 
      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Treść została skopiowana do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

    },
    mounted() {

    },
    watch: {

    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
  code { color: #4e82f7 !important; }
</style>