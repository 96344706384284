<template> 

  <div class="container pt-4 mb-5">

    <b-button variant="light" :to="('/app/bg-ai/')">
      <span class="material-symbols-outlined align-middle">chevron_left</span>
      Wróć
    </b-button>
 
    <div>
      
      <b-row align-v="center">

        <b-col lg="12">
 
          <div class="mt-4">

            <div class="mt-3">

              <label>Wpisz czym chcesz się zajmować, co chcesz sprzedawać:</label>

              <b-input-group>

                <b-form-input v-model="produkt" size="lg" ref="produkt" placeholder="np. suplementy diety, biżuterię, zdrową żywność itd."></b-form-input>
                
              </b-input-group>
 
            </div>

            <div class="mt-3">

              <label>Napisz w jakiej branży chcesz działać:</label>

              <b-input-group>

                <b-form-input v-model="branza" size="lg" ref="branza" placeholder="np. kursy z psychologii"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Opisz grupę docelową:</label>

              <b-input-group>

                <b-form-input v-model="klient" size="lg" ref="klient" placeholder="np. mężczyzna w wieku 30-40 lat, który szuka mieszkania w dużym mieście"></b-form-input>

              </b-input-group>

            </div>

          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje treść <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowana treść</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;">

              <div v-if="result" v-html="result" class="pb-3 mb-3" style="border-bottom: 1px solid #ddd;"></div>

            </div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
   
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>
 
    </div>

  </div>

</template>

<script>

  export default {
    name: 'apxc',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        result2: "",
        selectedText: '',
        query: "",
        produkt: "",
        branza: "",
        klient: "",
        finishContent: '',
        selectedSchema: null
      }
    },
    methods: {

      generateFinish: function() {

        if(!this.produkt) return false;
        if(!this.branza) return false;
        if(!this.klient) return false;

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "Stwórz kompleksowy plan marketingowy dla " + this.produkt + " grupą docelową są osoby " + this.klient + ". \n" +
            ". Plan powinien zawierać:\n" +
            "1.\tAnalizę grupy docelowej (wiek, płeć, zainteresowania, potrzeby, problemy).\n" +
            "2.\tOkreślenie unikalnej propozycji wartości (USP).\n" +
            "3.\tStrategię komunikacji (kluczowe przekazy, ton i styl komunikacji).\n" +
            "4.\tDobór odpowiednich kanałów marketingowych (online i offline).\n" +
            "5.\tPlan kampanii promocyjnych (treści, narzędzia, harmonogram).\n" +
            "6.\tPropozycje współpracy z influencerami i partnerami biznesowymi.\n" +
            "7.\tStrategie budowania zaangażowania (social media, e-mail marketing, wydarzenia).\n" +
            "8.\tPomysły na generowanie leadów i zwiększanie sprzedaży.\n" +
            "9.\tPlan monitorowania i mierzenia efektywności działań (KPI).\n" +
            "Uwzględnij nowoczesne strategie marketingowe, psychologię perswazji, dane rynkowe i najlepsze praktyki w branży " + this.branza + ".";

        query = query + "Używaj znaczników HTML zamiast markdown. Dozwolone znaczniki: Pogrubienie czyli <B></B>, i nowa linia <br/> . Zamiast znaczników nagłówków Hx użyj po prostu pogrubienia.";

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query, 'v': 'o1' }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data
 
          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');


          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowana treść"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },
 
      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Treść została skopiowana do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

    },
    mounted() {

      if (localStorage.bgai_branza) {
        this.branza = localStorage.bgai_branza;
      }

      if (localStorage.bgai_produkt) {
        this.produkt = localStorage.bgai_produkt;
      }

      if (localStorage.bgai_klient) {
        this.klient = localStorage.bgai_klient;
      }

    },
    watch: {

      branza(data) {
        localStorage.bgai_branza = data;
      },

      produkt(data) {
        localStorage.bgai_produkt = data;
      },

      klient(data) {
        localStorage.bgai_klient = data;
      }

    }


  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
  code { color: #4e82f7 !important; }
</style>