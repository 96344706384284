<template> 

  <div>

    <Loader text="Ładowanie aplikacji" v-if="!user.id" />

    <div class="container pt-4" v-if="user.id">

      <h2 class="fw-600">{{ app.title }}</h2>

      <AppReelsKeywordsGenerator v-if="app.slug == 'reels-keywords-generator'"/>
      <AppInfluencerGenerator v-if="app.slug == 'influencer-generator'"/>
      <App1MilionCoaching v-if="app.slug == '1milion-coaching'"/>
      <AppAiPostCreator v-if="app.slug == 'ai-post-creator'"/>
      <AppSoMeAiWriter v-if="app.slug == 'someai-writer'"/>
      <AppAiSellingMachine v-if="app.slug == 'ai-selling-machine'"/>
      <AppAiVirtualAssistanceAi v-if="app.slug == 'virtual-assistance-ai'"/>
      <AppCelebrityPostWriterAi v-if="app.slug == 'celebrity-post-writer-ai'"/>
      <AppTranslatorAi v-if="app.slug == 'translator-ai'"/>
      <AppGraphicAi v-if="app.slug == 'graphic-ai'"/>
      <AppGraphicNAi v-if="app.slug == 'graphic-n-ai'"/>
      <AppGraphicNNAi v-if="app.slug == 'graphic-nn-ai'"/>
      <AppHeadlineGeneratorAi v-if="app.slug == 'headline-generator-ai'"/>
      <AppOfferGeneratorAi v-if="app.slug == 'offer-generator-ai'"/>
      <AppPromptGenerator v-if="app.slug == 'prompt-generator'"/>
      <AppCopyPuzzleAi v-if="app.slug == 'copy-puzzle-ai'"/>
      <AppCopyLegendAi v-if="app.slug == 'copy-legend-ai'"/>
      <AppCopyBoosterAi v-if="app.slug == 'copy-booster-ai'"/>
      <AppMemeGeneratorAi v-if="app.slug == 'meme-generator-ai'"/>
      <AppCopyAssistantAi v-if="app.slug == 'copy-assistant-ai'"/>
      <AppBizBrainAi v-if="app.slug == 'biz-brain-ai'"/>
      <AppArticleGeniusAi v-if="app.slug == 'article-genius-ai'"/>
      <AppAskAi v-if="app.slug == 'ask-ai'"/>
      <AppVirtualoAi v-if="app.slug == 'virtualo-ai'"/>
      <AppArticleHeadlineAi v-if="app.slug == 'article-headline-ai'"/>
      <AppKorektorAi v-if="app.slug == 'korektor-ai'"/>
      <AppBussinesMentorAi v-if="app.slug == 'bussines-mentor-ai'"/>
      <AppMotivationalPostAi v-if="app.slug == 'motivational-post-ai'"/>
      <AppHeadlineBooster v-if="app.slug == 'headline-booster'"/>
      <AppBlogPostAi v-if="app.slug == 'blog-post-ai'"/>
      <AppSeoWriterAi v-if="app.slug == 'seo-writer-ai'"/>
      <AppEcomCopywriterAI v-if="app.slug == 'ecom-copywriter-ai'"/>
      <AppCopyMasterAI v-if="app.slug == 'copy-master-ai'"/>
      <AppEcomArticleSEOAI v-if="app.slug == 'ecom-article-seo-ai'"/>
      <App12XSellingMachineAI v-if="app.slug == '12x-selling-machine-ai'"/>
      <AppEcomAudytorAI v-if="app.slug == 'ecom-audytor-ai'"/>
      <AppKeywordsGeneratorAI v-if="app.slug == 'keywords-generator-ai'"/>
      <AppPerfectCopyAI v-if="app.slug == 'perfect-copy-ai'"/>
      <AppEcomFunnelGeneratorAI v-if="app.slug == 'ecom-funnel-generator-ai'"/>
      <AppArticleSEOIdeas v-if="app.slug == 'article-seo-ideas'"/>
      <AppGraphicAltAI v-if="app.slug == 'graphic-alt-ai'"/>
      <AppRealEstateAI v-if="app.slug == 'real-estate-ai'"/>
      <AppEcomGeneratorAI v-if="app.slug == 'ecom-generator-ai'"/>
      <AppSocialSellingMachineAI v-if="app.slug == 'social-selling-machine-ai'"/>
      <AppTargetDetectiveAI v-if="app.slug == 'target-detective-ai'"/>
      <AppTOPBrandCopyAI v-if="app.slug == 'top-brand-copy-ai'"/>
      <AppViralOpenerAI v-if="app.slug == 'viral-opener-ai'"/>
      <AppTikTokViralGeneratorAI v-if="app.slug == 'tiktok-viral-generator-ai'"/>
      <AppEduReelsGeneratorAI v-if="app.slug == 'edu-reels-generator-ai'"/>
      <AppViralHookGeneratorAI v-if="app.slug == 'viral-hook-generator-ai'"/>
      <AppSlideGenerator v-if="app.slug == 'slide-generator'"/>
      <AppQuizzGeneratorAI v-if="app.slug == 'quizz-generator-ai'"/>
      <AppModuleTitleGenerator  v-if="app.slug == 'module-title-generator-ai'"/>
      <AppMindMapGeneratorAI  v-if="app.slug == 'mind-map-generator-ai'"/>
      <AppCourseTitleGeneratorAI  v-if="app.slug == 'course-title-generator-ai'"/>
      <AppCoursePlannerAI  v-if="app.slug == 'course-planner-ai'"/>
      <AppCourseAssistantAI  v-if="app.slug == 'course-assistant-ai'"/>
      <AppCourseIdeaGeneratorAI  v-if="app.slug == 'course-idea-generator-ai'"/>
      <AppCourseValidatorAI  v-if="app.slug == 'course-validator-ai'"/>
      <AppCourseOfferGenerator  v-if="app.slug == 'course-offer-generator-ai'"/>
      <AppEmailSellingSequenceAI  v-if="app.slug == 'email-selling-sequence-ai'"/>
      <AppExpertGeniusAI  v-if="app.slug == 'expert-genius-ai'"/>
      <AppAICourseGenerator  v-if="app.slug == 'ai-course-generator'"/>
      <AppStyleClonerAI  v-if="app.slug == 'style-cloner-ai'"/>
      <AppStorytellerAI  v-if="app.slug == 'storyteller-ai'"/>
      <AppChapterTitleGeneratorAI  v-if="app.slug == 'chapter-title-generator-ai'"/>
      <AppEbookSellingCheckerAI  v-if="app.slug == 'ebook-selling-checker-ai'"/>
      <AppIlustratorAI  v-if="app.slug == 'ilustrator-ai'"/>
      <AppOutroEbookGeneratorAI  v-if="app.slug == 'outro-ebook-generator-ai'"/>
      <AppIntroEbookGeneratorAI  v-if="app.slug == 'intro-book-generator-ai'"/>
      <AppEbookArticleAI  v-if="app.slug == 'ebook-article-ai'"/>
      <AppEbookXXLAI  v-if="app.slug == 'ebook-xxl-ai'"/>
      <AppEbookAssistantAI  v-if="app.slug == 'ebook-assistant-ai'"/>
      <AppEbookPromoCalendar v-if="app.slug == 'ebook-promo-calendar'"/>
      <AppEbookOfferGeneratorAI  v-if="app.slug == 'ebook-offer-generator-ai'"/>
      <AppVideoToEbookAI  v-if="app.slug == 'video-to-ebook-ai'"/>
      <AppEbookPromotorAI  v-if="app.slug == 'ebook-promotor-ai'"/>
      <AppEbookSalesFunnelAI  v-if="app.slug == 'ebook-sales-funnel'"/>
      <AppContentsGeneratorAI  v-if="app.slug == 'contents-generator-ai'"/>
      <AppEbookIdeaGeneratorAI  v-if="app.slug == 'ebook-idea-generator-ai'"/>
      <AppEbookTitleGenerator  v-if="app.slug == 'ebook-title-generator-ai'"/>
      <AppAuthorAI  v-if="app.slug == 'author-ai'"/>
      <AppEbookEmailSequenceAI  v-if="app.slug == 'ebook-email-sequence-ai'"/>
      <AppAIEbookGenerator  v-if="app.slug == 'ai-ebook-generator'"/>
      <AppAudienceTargetingAI  v-if="app.slug == 'audience-targeting-ai'"/>
      <AppBonusGeneratorAI  v-if="app.slug == 'bonus-generator-ai'"/>
      <AppChallengeGeneratorAI  v-if="app.slug == 'challenge-generator-ai'"/>
      <AppCrushingObjectionsAI  v-if="app.slug == 'crushing-objections-ai'"/>
      <AppEmailsInvitingAI  v-if="app.slug == 'emails-inviting-ai'"/>
      <AppWebinarTitleGeneratorAI  v-if="app.slug == 'webinar-title-generator-ai'"/>
      <AppWebinarIdeaAI  v-if="app.slug == 'webinar-idea-ai'"/>
      <AppEducationWebinarAI  v-if="app.slug == 'education-webinar-ai'"/>
      <AppEmailEducationGeneratorAI  v-if="app.slug == 'email-education-generator-ai'"/>
      <AppReelsWebinarGeneratorAI  v-if="app.slug == 'reels-webinar-generator-ai'"/>
      <AppWebinarAssistanceAI  v-if="app.slug == 'webinar-assistance-ai'"/>
      <AppWebinarValidatorAI  v-if="app.slug == 'webinar-validator-ai'"/>
      <AppWebinarGraphicAI  v-if="app.slug == 'webinar-graphic-ai'"/>
      <AppWebinarADSGeneratorAI  v-if="app.slug == 'webinar-ads-generator-ai'"/>
      <AppSMWebinarPromotor  v-if="app.slug == 'sm-webinar-promotor'"/>
      <AppWebinarAgendaAI  v-if="app.slug == 'webinar-agenda-ai'"/>
      <AppEngagementBoosterAI  v-if="app.slug == 'engagement-booster-ai'"/>
      <AppQASessionGeneratorAI  v-if="app.slug == 'qa-session-generator-ai'"/>
      <AppSellingArgumentGeneratorAI  v-if="app.slug == 'selling-argument-generator-ai'"/>
      <AppVideoScriptAI  v-if="app.slug == 'video-script-ai'"/>
      <AppWebinarPresentionAI  v-if="app.slug == 'webinar-presention-ai'"/>
      <AppWebinarFunnelGeneratorAI  v-if="app.slug == 'webinar-funnel-generator-ai'"/>
      <AppBGAI  v-if="app.slug == 'bg-ai'"/>
      <AppBGAIAvatarGenerator  v-if="app.slug == 'bg-ai-avatar-generator'"/>
      <AppBGAIEmpathyMapGen  v-if="app.slug == 'bg-ai-empathy-map-gen'"/>
      <AppBGAINicheGen  v-if="app.slug == 'bg-ai-niche-gen'"/>
      <AppBGAIAskAI  v-if="app.slug == 'bg-ai-ask-ai'"/>
      <AppBGAIProblemSolveGen  v-if="app.slug == 'bg-ai-problem-solve-gen'"/>
      <AppBGAIFormGeneratorAi  v-if="app.slug == 'bg-ai-form-generator-ai'"/>
      <AppBGAIMicroproblemsGenAI  v-if="app.slug == 'bg-ai-microproblems-gen-ai'"/>
      <AppBGAIPainPointGen  v-if="app.slug == 'bg-ai-pain-point-gen'"/>
      <AppBGAISMStrategist  v-if="app.slug == 'bg-ai-sm-strategist'"/>
      <AppBGAIFBPostGen  v-if="app.slug == 'bg-ai-fb-post-gen'"/>
      <AppBGAIFBGroupPostGen  v-if="app.slug == 'bg-ai-fb-group-post-gen'"/>
      <AppBGAIInstaPostGen  v-if="app.slug == 'bg-ai-insta-post-gen'"/>
      <AppBGAIThreadsGen  v-if="app.slug == 'bg-ai-threads-gen'"/>
      <AppBGAIEduStoriesGen  v-if="app.slug == 'bg-ai-edu-stories-gen'"/>
      <AppBGAIReelsGen  v-if="app.slug == 'bg-ai-reels-gen'"/>
      <AppBGAIFacelessReelsGen  v-if="app.slug == 'bg-ai-faceless-reels-gen'"/>
      <AppBGAITTGen  v-if="app.slug == 'bg-ai-tt-gen'"/>
      <AppBGAILinkedPostGen  v-if="app.slug == 'bg-ai-linked-post-gen'"/>
      <AppBGAIYtGenAi  v-if="app.slug == 'bg-ai-yt-gen-ai'"/>
      <AppBGAIArticleBlogGen  v-if="app.slug == 'bg-ai-article-blog-gen'"/>
      <AppBGAINewsletterGen  v-if="app.slug == 'bg-ai-newsletter-gen'"/>
      <AppBGAIXGen  v-if="app.slug == 'bg-ai-x-gen'"/>
      <AppBGAILeadMagnetGen  v-if="app.slug == 'bg-ai-lead-magnet-gen'"/>
      <AppBGAIFreeRaportGen  v-if="app.slug == 'bg-ai-free-raport-gen'"/>
      <AppBGAILandingPagesGen  v-if="app.slug == 'bg-ai-landing-pages-gen'"/>
      <AppBGAIWelcomeEmailGen  v-if="app.slug == 'bg-ai-welcome-email-gen'"/>
      <AppBGAISocialPostGen  v-if="app.slug == 'bg-ai-social-post-gen'"/>
      <AppBGAIEngagementStoriesGen  v-if="app.slug == 'bg-ai-engagement-stories-gen'"/>
      <AppBGAICommentGenAi  v-if="app.slug == 'bg-ai-comment-gen-ai'"/>
      <AppBGAIRespondGen  v-if="app.slug == 'bg-ai-respond-gen'"/>
      <AppBGAIQuestionStoriesGen  v-if="app.slug == 'bg-ai-question-stories-gen'"/>
      <AppBGAIProductGen  v-if="app.slug == 'bg-ai-product-gen'"/>
      <AppBGAIBussinesModelGen  v-if="app.slug == 'bg-ai-bussines-model-gen'"/>
      <AppBGAIInnovationGen  v-if="app.slug == 'bg-ai-innovation-gen'"/>
      <AppBGAINameProductGen  v-if="app.slug == 'bg-ai-name-product-gen'"/>
      <AppBGAIMarketAnalizerAi v-if="app.slug == 'bg-ai-market-analizer-ai'"/>
      <AppBGAIUspGen v-if="app.slug == 'bg-ai-usp-gen'"/>
      <AppBGAISellIdeaGen v-if="app.slug == 'bg-ai-sell-idea-gen'"/>
      <AppBGAISellingPostGen v-if="app.slug == 'bg-ai-selling-post-gen'"/>
      <AppBGAIOfferGen v-if="app.slug == 'bg-ai-offer-gen'"/>
      <AppBGAIMarketingPlannerGen v-if="app.slug == 'bg-ai-marketing-planner-gen'"/>
      <AppBGAIFacebookAdsGen v-if="app.slug == 'bg-ai-facebook-ads-gen'"/>
      <AppBGAIEmailSellingGen v-if="app.slug == 'bg-ai-email-selling-gen'"/>
      <AppBGAIPhoneScriptGen v-if="app.slug == 'bg-ai-phone-script-gen'"/>
      <AppBGAIScalingBussinesAi v-if="app.slug == 'bg-ai-scaling-bussines-ai'"/>
      <AppBGAIDelegationAi v-if="app.slug == 'bg-ai-delegation-ai'"/>
      <AppBGAILTVGen v-if="app.slug == 'bg-ai-ltv-gen'"/>
      <AppBGAIProductLadderGen v-if="app.slug == 'bg-ai-product-ladder-gen'"/>
      <AppBGAIOptiBudgetAi v-if="app.slug == 'bg-ai-opti-budget-ai'"/>
      <AppBussinesPlannerAI v-if="app.slug == 'bussines-planner-ai'"/>
      <AppCelebrityPostAI v-if="app.slug == 'celebrilty-post-ai'"/>
      <AppProcrastinationStoperAI v-if="app.slug == 'procrastination-stoper-ai'"/>
      <AppDailyRoutineAI v-if="app.slug == 'daily-routine-ai'"/>
      <AppYouTubePlannerAI v-if="app.slug == 'youtube-planner-ai'"/>
      <AppSummarizerAI v-if="app.slug == 'summarizer-ai'"/>
      <AppBiznesAssistantAI v-if="app.slug == 'biznes-assistant-ai'"/>
      <AppCopyGenius v-if="app.slug == 'copy-genius'"/>
      <AppSocialMediaCalendar v-if="app.slug == 'social-media-calendar'"/>
      <AppMillionaireMindsetGenerator v-if="app.slug == 'millionaire-mindset-generator'"/>
      <AppMillionaireMindsetGenerator1 v-if="app.slug == 'millionaire-mindset-generator-1'"/>
      <AppMillionaireMindsetGenerator2 v-if="app.slug == 'millionaire-mindset-generator-2'"/>
      <AppMillionaireMindsetGenerator3 v-if="app.slug == 'millionaire-mindset-generator-3'"/>
      <AppMillionaireMindsetGenerator4 v-if="app.slug == 'millionaire-mindset-generator-4'"/>
      <AppMillionaireMindsetGenerator5 v-if="app.slug == 'millionaire-mindset-generator-5'"/>
      <AppMillionaireMindsetGenerator6 v-if="app.slug == 'millionaire-mindset-generator-6'"/>
      <AppMillionaireMindsetGenerator7 v-if="app.slug == 'millionaire-mindset-generator-7'"/>
      <AppMillionaireMindsetGenerator8 v-if="app.slug == 'millionaire-mindset-generator-8'"/>
      <AppMillionaireMindsetGenerator9 v-if="app.slug == 'millionaire-mindset-generator-9'"/>
      <AppMillionaireMindsetGenerator10 v-if="app.slug == 'millionaire-mindset-generator-10'"/>
      <AppBusinessCalculator2 v-if="app.slug == 'business-calculator-2'"/>
      <AppBusinessCalculator21 v-if="app.slug == 'business-calculator-2-1'"/>
      <AppBusinessCalculator22 v-if="app.slug == 'business-calculator-2-2'"/>
      <AppBusinessCalculator23 v-if="app.slug == 'business-calculator-2-3'"/>
      <AppGraphicPromptCreator v-if="app.slug == 'graphic-prompt-creator'"/>
      <AppGraphicPromptCreator1 v-if="app.slug == 'graphic-prompt-creator-1'"/>
      <AppGraphicPromptCreator2 v-if="app.slug == 'graphic-prompt-creator-2'"/>

    </div>

  </div>
  
</template>

<script>
  
  import Loader from '../../elements/Loader';
  import AppReelsKeywordsGenerator from '../../elements/Apps/AppReelsKeywordsGenerator';
  import AppInfluencerGenerator from '../../elements/Apps/AppInfluencerGenerator';
  import App1MilionCoaching from '../../elements/Apps/App1MilionCoaching';
  import AppAiPostCreator from '../../elements/Apps/AppAiPostCreator';
  import AppSoMeAiWriter from '../../elements/Apps/AppSoMeAiWriter';
  import AppAiSellingMachine from '../../elements/Apps/AppAiSellingMachine';
  import AppAiVirtualAssistanceAi from '../../elements/Apps/AppAiVirtualAssistanceAi';
  import AppCelebrityPostWriterAi from '../../elements/Apps/AppCelebrityPostWriterAi';
  import AppTranslatorAi from '../../elements/Apps/AppTranslatorAi';
  import AppGraphicAi from '../../elements/Apps/AppGraphicAi';
  import AppGraphicNAi from '../../elements/Apps/AppGraphicNAi';
  import AppGraphicNNAi from '../../elements/Apps/AppGraphicNNAi';
  import AppHeadlineGeneratorAi from '../../elements/Apps/AppHeadlineGeneratorAi';
  import AppOfferGeneratorAi from '../../elements/Apps/AppOfferGeneratorAi';
  import AppPromptGenerator from '../../elements/Apps/AppPromptGenerator';
  import AppCopyPuzzleAi from '../../elements/Apps/AppCopyPuzzleAi';
  import AppCopyLegendAi from '../../elements/Apps/AppCopyLegendAi';
  import AppCopyBoosterAi from '../../elements/Apps/AppCopyBoosterAi';
  import AppMemeGeneratorAi from '../../elements/Apps/AppMemeGeneratorAi';
  import AppCopyAssistantAi from '../../elements/Apps/AppCopyAssistantAi';
  import AppBizBrainAi from '../../elements/Apps/AppBizBrainAi';
  import AppArticleGeniusAi from '../../elements/Apps/AppArticleGeniusAi';
  import AppAskAi from '../../elements/Apps/AppAskAi';
  import AppVirtualoAi from '../../elements/Apps/AppVirtualoAi';
  import AppArticleHeadlineAi from '../../elements/Apps/AppArticleHeadlineAi';
  import AppKorektorAi from '../../elements/Apps/AppKorektorAi';
  import AppBussinesMentorAi from '../../elements/Apps/AppBussinesMentorAi';
  import AppMotivationalPostAi from '../../elements/Apps/AppMotivationalPostAi';
  import AppHeadlineBooster from '../../elements/Apps/AppHeadlineBooster';
  import AppBlogPostAi from '../../elements/Apps/AppBlogPostAi';
  import AppSeoWriterAi from '../../elements/Apps/AppSeoWriterAi';
  import AppEcomCopywriterAI from '../../elements/Apps/AppEcomCopywriterAI';
  import AppCopyMasterAI from '../../elements/Apps/AppCopyMasterAI';
  import AppEcomArticleSEOAI from '../../elements/Apps/AppEcomArticleSEOAI';
  import App12XSellingMachineAI from '../../elements/Apps/App12XSellingMachineAI';
  import AppEcomAudytorAI from '../../elements/Apps/AppEcomAudytorAI';
  import AppKeywordsGeneratorAI from '../../elements/Apps/AppKeywordsGeneratorAI';
  import AppPerfectCopyAI from '../../elements/Apps/AppPerfectCopyAI';
  import AppEcomFunnelGeneratorAI from '../../elements/Apps/AppEcomFunnelGeneratorAI';
  import AppArticleSEOIdeas from '../../elements/Apps/AppArticleSEOIdeas';
  import AppGraphicAltAI from '../../elements/Apps/AppGraphicAltAI';
  import AppRealEstateAI from '../../elements/Apps/AppRealEstateAI';
  import AppEcomGeneratorAI from '../../elements/Apps/AppEcomGeneratorAI';
  import AppSocialSellingMachineAI from '../../elements/Apps/AppSocialSellingMachineAI';
  import AppTargetDetectiveAI from '../../elements/Apps/AppTargetDetectiveAI';
  import AppTOPBrandCopyAI from '../../elements/Apps/AppTOPBrandCopyAI';
  import AppViralOpenerAI from '../../elements/Apps/AppViralOpenerAI';
  import AppTikTokViralGeneratorAI from '../../elements/Apps/AppTikTokViralGeneratorAI';
  import AppEduReelsGeneratorAI from '../../elements/Apps/AppEduReelsGeneratorAI';
  import AppViralHookGeneratorAI from '../../elements/Apps/AppViralHookGeneratorAI';
  import AppSlideGenerator from '../../elements/Apps/AppSlideGenerator';
  import AppQuizzGeneratorAI from '../../elements/Apps/AppQuizzGeneratorAI';
  import AppModuleTitleGenerator from '../../elements/Apps/AppModuleTitleGenerator';
  import AppMindMapGeneratorAI from '../../elements/Apps/AppMindMapGeneratorAI';
  import AppCourseTitleGeneratorAI from '../../elements/Apps/AppCourseTitleGeneratorAI';
  import AppCoursePlannerAI from '../../elements/Apps/AppCoursePlannerAI';
  import AppCourseAssistantAI from '../../elements/Apps/AppCourseAssistantAI';
  import AppCourseIdeaGeneratorAI from '../../elements/Apps/AppCourseIdeaGeneratorAI';
  import AppCourseValidatorAI from '../../elements/Apps/AppCourseValidatorAI';
  import AppCourseOfferGenerator from '../../elements/Apps/AppCourseOfferGenerator';
  import AppEmailSellingSequenceAI from '../../elements/Apps/AppEmailSellingSequenceAI';
  import AppExpertGeniusAI from '../../elements/Apps/AppExpertGeniusAI';
  import AppAICourseGenerator from '../../elements/Apps/AppAICourseGenerator';
  import AppStyleClonerAI from '../../elements/Apps/AppStyleClonerAI';
  import AppStorytellerAI from '../../elements/Apps/AppStorytellerAI';
  import AppChapterTitleGeneratorAI from '../../elements/Apps/AppChapterTitleGeneratorAI';
  import AppEbookSellingCheckerAI from '../../elements/Apps/AppEbookSellingCheckerAI';
  import AppIlustratorAI from '../../elements/Apps/AppIlustratorAI';
  import AppOutroEbookGeneratorAI from '../../elements/Apps/AppOutroEbookGeneratorAI';
  import AppIntroEbookGeneratorAI from '../../elements/Apps/AppIntroEbookGeneratorAI';
  import AppEbookArticleAI from '../../elements/Apps/AppEbookArticleAI';
  import AppEbookXXLAI from '../../elements/Apps/AppEbookXXLAI';
  import AppEbookAssistantAI from '../../elements/Apps/AppEbookAssistantAI';
  import AppEbookPromoCalendar from '../../elements/Apps/AppEbookPromoCalendar';
  import AppEbookOfferGeneratorAI from '../../elements/Apps/AppEbookOfferGeneratorAI';
  import AppVideoToEbookAI from '../../elements/Apps/AppVideoToEbookAI';
  import AppEbookPromotorAI from '../../elements/Apps/AppEbookPromotorAI';
  import AppEbookSalesFunnelAI from '../../elements/Apps/AppEbookSalesFunnelAI';
  import AppContentsGeneratorAI from '../../elements/Apps/AppContentsGeneratorAI';
  import AppEbookIdeaGeneratorAI from '../../elements/Apps/AppEbookIdeaGeneratorAI';
  import AppEbookTitleGenerator from '../../elements/Apps/AppEbookTitleGenerator';
  import AppAuthorAI from '../../elements/Apps/AppAuthorAI';
  import AppEbookEmailSequenceAI from '../../elements/Apps/AppEbookEmailSequenceAI';
  import AppAIEbookGenerator from '../../elements/Apps/AppAIEbookGenerator';
  import AppAudienceTargetingAI from '../../elements/Apps/AppAudienceTargetingAI';
  import AppBonusGeneratorAI from '../../elements/Apps/AppBonusGeneratorAI';
  import AppChallengeGeneratorAI from '../../elements/Apps/AppChallengeGeneratorAI';
  import AppCrushingObjectionsAI from '../../elements/Apps/AppCrushingObjectionsAI';
  import AppEmailsInvitingAI from '../../elements/Apps/AppEmailsInvitingAI';
  import AppWebinarTitleGeneratorAI from '../../elements/Apps/AppWebinarTitleGeneratorAI';
  import AppWebinarIdeaAI from '../../elements/Apps/AppWebinarIdeaAI';
  import AppEducationWebinarAI from '../../elements/Apps/AppEducationWebinarAI';
  import AppEmailEducationGeneratorAI from '../../elements/Apps/AppEmailEducationGeneratorAI';
  import AppReelsWebinarGeneratorAI from '../../elements/Apps/AppReelsWebinarGeneratorAI';
  import AppWebinarAssistanceAI from '../../elements/Apps/AppWebinarAssistanceAI';
  import AppWebinarValidatorAI from '../../elements/Apps/AppWebinarValidatorAI';
  import AppWebinarGraphicAI from '../../elements/Apps/AppWebinarGraphicAI';
  import AppWebinarADSGeneratorAI from '../../elements/Apps/AppWebinarADSGeneratorAI';
  import AppSMWebinarPromotor from '../../elements/Apps/AppSMWebinarPromotor';
  import AppWebinarAgendaAI from '../../elements/Apps/AppWebinarAgendaAI';
  import AppEngagementBoosterAI from '../../elements/Apps/AppEngagementBoosterAI';
  import AppQASessionGeneratorAI from '../../elements/Apps/AppQASessionGeneratorAI';
  import AppSellingArgumentGeneratorAI from '../../elements/Apps/AppSellingArgumentGeneratorAI';
  import AppVideoScriptAI from '../../elements/Apps/AppVideoScriptAI';
  import AppWebinarPresentionAI from '../../elements/Apps/AppWebinarPresentionAI';
  import AppWebinarFunnelGeneratorAI from '../../elements/Apps/AppWebinarFunnelGeneratorAI';
  import AppBGAI from '../../elements/Apps/AppBGAI';
  import AppBGAIAvatarGenerator from '../../elements/Apps/AppBGAIAvatarGenerator';
  import AppBGAIEmpathyMapGen from '../../elements/Apps/AppBGAIEmpathyMapGen';
  import AppBGAINicheGen from '../../elements/Apps/AppBGAINicheGen';
  import AppBGAIAskAI from '../../elements/Apps/AppBGAIAskAI';
  import AppBGAIProblemSolveGen from '../../elements/Apps/AppBGAIProblemSolveGen';
  import AppBGAIFormGeneratorAi from '../../elements/Apps/AppBGAIFormGeneratorAi';
  import AppBGAIMicroproblemsGenAI from '../../elements/Apps/AppBGAIMicroproblemsGenAI';
  import AppBGAIPainPointGen from '../../elements/Apps/AppBGAIPainPointGen';
  import AppBGAISMStrategist from '../../elements/Apps/AppBGAISMStrategist';
  import AppBGAIFBPostGen from '../../elements/Apps/AppBGAIFBPostGen';
  import AppBGAIFBGroupPostGen from '../../elements/Apps/AppBGAIFBGroupPostGen';
  import AppBGAIInstaPostGen from '../../elements/Apps/AppBGAIInstaPostGen';
  import AppBGAIThreadsGen from '../../elements/Apps/AppBGAIThreadsGen';
  import AppBGAIEduStoriesGen from '../../elements/Apps/AppBGAIEduStoriesGen';
  import AppBGAIReelsGen from '../../elements/Apps/AppBGAIReelsGen';
  import AppBGAIFacelessReelsGen from '../../elements/Apps/AppBGAIFacelessReelsGen';
  import AppBGAITTGen from '../../elements/Apps/AppBGAITTGen';
  import AppBGAILinkedPostGen from '../../elements/Apps/AppBGAILinkedPostGen';
  import AppBGAIYtGenAi from '../../elements/Apps/AppBGAIYtGenAi';
  import AppBGAIArticleBlogGen from '../../elements/Apps/AppBGAIArticleBlogGen';
  import AppBGAINewsletterGen from '../../elements/Apps/AppBGAINewsletterGen';
  import AppBGAIXGen from '../../elements/Apps/AppBGAIXGen';
  import AppBGAILeadMagnetGen from '../../elements/Apps/AppBGAILeadMagnetGen';
  import AppBGAIFreeRaportGen from '../../elements/Apps/AppBGAIFreeRaportGen';
  import AppBGAILandingPagesGen from '../../elements/Apps/AppBGAILandingPagesGen';
  import AppBGAIWelcomeEmailGen from '../../elements/Apps/AppBGAIWelcomeEmailGen';
  import AppBGAISocialPostGen from '../../elements/Apps/AppBGAISocialPostGen';
  import AppBGAIEngagementStoriesGen from '../../elements/Apps/AppBGAIEngagementStoriesGen';
  import AppBGAICommentGenAi from '../../elements/Apps/AppBGAICommentGenAi';
  import AppBGAIRespondGen from '../../elements/Apps/AppBGAIRespondGen';
  import AppBGAIQuestionStoriesGen from '../../elements/Apps/AppBGAIQuestionStoriesGen';
  import AppBGAIProductGen from '../../elements/Apps/AppBGAIProductGen';
  import AppBGAIBussinesModelGen from '../../elements/Apps/AppBGAIBussinesModelGen';
  import AppBGAIInnovationGen from '../../elements/Apps/AppBGAIInnovationGen';
  import AppBGAINameProductGen from '../../elements/Apps/AppBGAINameProductGen';
  import AppBGAIMarketAnalizerAi from '../../elements/Apps/AppBGAIMarketAnalizerAi';
  import AppBGAIUspGen from '../../elements/Apps/AppBGAIUspGen';
  import AppBGAISellIdeaGen from '../../elements/Apps/AppBGAISellIdeaGen';
  import AppBGAISellingPostGen from '../../elements/Apps/AppBGAISellingPostGen';
  import AppBGAIOfferGen from '../../elements/Apps/AppBGAIOfferGen';
  import AppBGAIMarketingPlannerGen from '../../elements/Apps/AppBGAIMarketingPlannerGen';
  import AppBGAIFacebookAdsGen from '../../elements/Apps/AppBGAIFacebookAdsGen';
  import AppBGAIEmailSellingGen from '../../elements/Apps/AppBGAIEmailSellingGen';
  import AppBGAIPhoneScriptGen from '../../elements/Apps/AppBGAIPhoneScriptGen';
  import AppBGAIScalingBussinesAi from '../../elements/Apps/AppBGAIScalingBussinesAi';
  import AppBGAIDelegationAi from '../../elements/Apps/AppBGAIDelegationAi';
  import AppBGAILTVGen from '../../elements/Apps/AppBGAILTVGen';
  import AppBGAIProductLadderGen from '../../elements/Apps/AppBGAIProductLadderGen';
  import AppBGAIOptiBudgetAi from '../../elements/Apps/AppBGAIOptiBudgetAi';
  import AppBussinesPlannerAI from '../../elements/Apps/AppBussinesPlannerAI';
  import AppCelebrityPostAI from '../../elements/Apps/AppCelebrityPostAI';
  import AppProcrastinationStoperAI from '../../elements/Apps/AppProcrastinationStoperAI';
  import AppDailyRoutineAI from '../../elements/Apps/AppDailyRoutineAI';
  import AppYouTubePlannerAI from '../../elements/Apps/AppYouTubePlannerAI';
  import AppSummarizerAI from '../../elements/Apps/AppSummarizerAI';
  import AppBiznesAssistantAI from '../../elements/Apps/AppBiznesAssistantAI';
  import AppCopyGenius from '../../elements/Apps/AppCopyGenius';
  import AppSocialMediaCalendar from '../../elements/Apps/AppSocialMediaCalendar';
  import AppMillionaireMindsetGenerator from '../../elements/Apps/AppMillionaireMindsetGenerator';
  import AppMillionaireMindsetGenerator1 from '../../elements/Apps/AppMillionaireMindsetGenerator1';
  import AppMillionaireMindsetGenerator2 from '../../elements/Apps/AppMillionaireMindsetGenerator2';
  import AppMillionaireMindsetGenerator3 from '../../elements/Apps/AppMillionaireMindsetGenerator3';
  import AppMillionaireMindsetGenerator4 from '../../elements/Apps/AppMillionaireMindsetGenerator4';
  import AppMillionaireMindsetGenerator5 from '../../elements/Apps/AppMillionaireMindsetGenerator5';
  import AppMillionaireMindsetGenerator6 from '../../elements/Apps/AppMillionaireMindsetGenerator6';
  import AppMillionaireMindsetGenerator7 from '../../elements/Apps/AppMillionaireMindsetGenerator7';
  import AppMillionaireMindsetGenerator8 from '../../elements/Apps/AppMillionaireMindsetGenerator8';
  import AppMillionaireMindsetGenerator9 from '../../elements/Apps/AppMillionaireMindsetGenerator9';
  import AppMillionaireMindsetGenerator10 from '../../elements/Apps/AppMillionaireMindsetGenerator10';
  import AppBusinessCalculator2 from '../../elements/Apps/AppBusinessCalculator2';
  import AppBusinessCalculator21 from '../../elements/Apps/AppBusinessCalculator21';
  import AppBusinessCalculator22 from '../../elements/Apps/AppBusinessCalculator22';
  import AppBusinessCalculator23 from '../../elements/Apps/AppBusinessCalculator23';
  import AppGraphicPromptCreator from '../../elements/Apps/AppGraphicPromptCreator';
  import AppGraphicPromptCreator1 from '../../elements/Apps/AppGraphicPromptCreator1';
  import AppGraphicPromptCreator2 from '../../elements/Apps/AppGraphicPromptCreator2';


  export default {
    name: 'SectionApp',
    components: {
      Loader,
      AppReelsKeywordsGenerator,
      AppInfluencerGenerator,
      App1MilionCoaching,
      AppAiPostCreator,
      AppSoMeAiWriter,
      AppAiSellingMachine,
      AppAiVirtualAssistanceAi,
      AppCelebrityPostWriterAi,
      AppTranslatorAi,
      AppGraphicAi,
      AppGraphicNAi,
      AppGraphicNNAi,
      AppHeadlineGeneratorAi,
      AppOfferGeneratorAi,
      AppPromptGenerator,
      AppCopyPuzzleAi,
      AppCopyLegendAi,
      AppCopyBoosterAi,
      AppMemeGeneratorAi,
      AppCopyAssistantAi,
      AppBizBrainAi,
      AppArticleGeniusAi,
      AppAskAi,
      AppVirtualoAi,
      AppArticleHeadlineAi,
      AppKorektorAi,
      AppBussinesMentorAi,
      AppMotivationalPostAi,
      AppHeadlineBooster,
      AppBlogPostAi,
      AppSeoWriterAi,
      AppEcomCopywriterAI,
      AppCopyMasterAI,
      AppEcomArticleSEOAI,
      App12XSellingMachineAI,
      AppEcomAudytorAI,
      AppKeywordsGeneratorAI,
      AppPerfectCopyAI,
      AppEcomFunnelGeneratorAI,
      AppArticleSEOIdeas,
      AppGraphicAltAI,
      AppRealEstateAI,
      AppEcomGeneratorAI,
      AppSocialSellingMachineAI,
      AppTargetDetectiveAI,
      AppTOPBrandCopyAI,
      AppViralOpenerAI,
      AppTikTokViralGeneratorAI,
      AppEduReelsGeneratorAI,
      AppViralHookGeneratorAI,
      AppSlideGenerator,
      AppQuizzGeneratorAI,
      AppModuleTitleGenerator,
      AppMindMapGeneratorAI,
      AppCourseTitleGeneratorAI,
      AppCoursePlannerAI,
      AppCourseAssistantAI,
      AppCourseIdeaGeneratorAI,
      AppCourseValidatorAI,
      AppCourseOfferGenerator,
      AppEmailSellingSequenceAI,
      AppExpertGeniusAI,
      AppAICourseGenerator,
      AppStyleClonerAI,
      AppStorytellerAI,
      AppChapterTitleGeneratorAI,
      AppEbookSellingCheckerAI,
      AppIlustratorAI,
      AppOutroEbookGeneratorAI,
      AppIntroEbookGeneratorAI,
      AppEbookArticleAI,
      AppEbookXXLAI,
      AppEbookAssistantAI,
      AppEbookPromoCalendar,
      AppEbookOfferGeneratorAI,
      AppVideoToEbookAI,
      AppEbookPromotorAI,
      AppEbookSalesFunnelAI,
      AppContentsGeneratorAI,
      AppEbookIdeaGeneratorAI,
      AppEbookTitleGenerator,
      AppAuthorAI,
      AppEbookEmailSequenceAI,
      AppAIEbookGenerator,
      AppAudienceTargetingAI,
      AppBonusGeneratorAI,
      AppChallengeGeneratorAI,
      AppCrushingObjectionsAI,
      AppEmailsInvitingAI,
      AppWebinarTitleGeneratorAI,
      AppWebinarIdeaAI,
      AppEducationWebinarAI,
      AppEmailEducationGeneratorAI,
      AppReelsWebinarGeneratorAI,
      AppWebinarAssistanceAI,
      AppWebinarValidatorAI,
      AppWebinarGraphicAI,
      AppWebinarADSGeneratorAI,
      AppSMWebinarPromotor,
      AppWebinarAgendaAI,
      AppEngagementBoosterAI,
      AppQASessionGeneratorAI,
      AppSellingArgumentGeneratorAI,
      AppVideoScriptAI,
      AppWebinarPresentionAI,
      AppWebinarFunnelGeneratorAI,
      AppBGAI,
      AppBGAIAvatarGenerator,
      AppBGAIEmpathyMapGen,
      AppBGAINicheGen,
      AppBGAIAskAI,
      AppBGAIProblemSolveGen,
      AppBGAIFormGeneratorAi,
      AppBGAIMicroproblemsGenAI,
      AppBGAIPainPointGen,
      AppBGAISMStrategist,
      AppBGAIFBPostGen,
      AppBGAIFBGroupPostGen,
      AppBGAIInstaPostGen,
      AppBGAIThreadsGen,
      AppBGAIEduStoriesGen,
      AppBGAIReelsGen,
      AppBGAIFacelessReelsGen,
      AppBGAITTGen,
      AppBGAILinkedPostGen,
      AppBGAIYtGenAi,
      AppBGAIArticleBlogGen,
      AppBGAINewsletterGen,
      AppBGAIXGen,
      AppBGAILeadMagnetGen,
      AppBGAIFreeRaportGen,
      AppBGAILandingPagesGen,
      AppBGAIWelcomeEmailGen,
      AppBGAISocialPostGen,
      AppBGAIEngagementStoriesGen,
      AppBGAICommentGenAi,
      AppBGAIRespondGen,
      AppBGAIQuestionStoriesGen,
      AppBGAIProductGen,
      AppBGAIBussinesModelGen,
      AppBGAIInnovationGen,
      AppBGAINameProductGen,
      AppBGAIMarketAnalizerAi,
      AppBGAIUspGen,
      AppBGAISellIdeaGen,
      AppBGAISellingPostGen,
      AppBGAIOfferGen,
      AppBGAIMarketingPlannerGen,
      AppBGAIFacebookAdsGen,
      AppBGAIEmailSellingGen,
      AppBGAIPhoneScriptGen,
      AppBGAIScalingBussinesAi,
      AppBGAIDelegationAi,
      AppBGAILTVGen,
      AppBGAIProductLadderGen,
      AppBGAIOptiBudgetAi,
      AppBussinesPlannerAI,
      AppCelebrityPostAI,
      AppProcrastinationStoperAI,
      AppDailyRoutineAI,
      AppYouTubePlannerAI,
      AppSummarizerAI,
      AppBiznesAssistantAI,
      AppCopyGenius,
      AppSocialMediaCalendar,
      AppMillionaireMindsetGenerator,
      AppMillionaireMindsetGenerator1,
      AppMillionaireMindsetGenerator2,
      AppMillionaireMindsetGenerator3,
      AppMillionaireMindsetGenerator4,
      AppMillionaireMindsetGenerator5,
      AppMillionaireMindsetGenerator6,
      AppMillionaireMindsetGenerator7,
      AppMillionaireMindsetGenerator8,
      AppMillionaireMindsetGenerator9,
      AppMillionaireMindsetGenerator10,
      AppBusinessCalculator2,
      AppBusinessCalculator21,
      AppBusinessCalculator22,
      AppBusinessCalculator23,
      AppGraphicPromptCreator,
      AppGraphicPromptCreator1,
      AppGraphicPromptCreator2,
    },
    data() {
      return {
        app: {},
        user: {
          id: null,
          permissions: []
        },
      }
    },
 
    created() {
        
      this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

        let data = response.data 

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        if(data.userId) {

          this.user.id = data.userId
          this.user.permissions = data.permissions


          if(this.$route.params.slug) {

            if(this.$route.params.app) 
              this.load(this.$route.params.slug, this.$route.params.app)
            else
              this.load(this.$route.params.slug);

          }

        }

      });

      // Set breadcumbs
      this.$root.$emit('system.breadcumber.set', [{label: "Aplikacje", link: "/apps"}])

    },

    methods: {
        
      load: function(slug) {

        if(slug) {
 
          this.axios.get(window.API_URL + "apps/getApp/" + slug).then((response) => {
            
            let data = response.data 
         
            this.app = data
             
            // Checking permissions
            if(!this.user.permissions.includes(this.app.neededPermissions)) {
              
              this.$router.push('/access-denied')
              
            }
 
          }) 
 
          window.scrollTo(0, 0)

        }

      }

    },

    watch: {

      '$route.params.slug' (slug) {

        this.load(slug, this.app.id)

      },

    },

    mounted() {
     
    }

  }

</script>

<style scoped>

  .table-of-contents > div {
    font-size: 0.9rem;
  }

  .list-group-item {
    border: 0px;
  }

  .list-group-item > a {
    color: #000;
  }

  .list-group-item > a.active {
    color: #165eff;
    font-weight: 600;
  }

  .app-title {
    font-size: 1.3rem;
  }
 
  .list-group-item:hover {
    background-color: #f9f9f9;
  }

  .list-group-item.current {
    background-color: #f9f9f9;
  }

</style> 