<template> 

  <div class="container pt-4 pb-4">

    <div>

      Wybierz co chcesz zrobić:
 
      <b-list-group class="mt-3">
        <b-list-group-item v-for="item in schema" class="pointer" :key="item.name" button @click="goto(item.slug)">
          
          <span class="material-symbols-outlined align-middle text-primary" v-if="item.type === 'app'">{{ item.icon }}</span>

          <span v-if="item.type === 'header'"><B>{{ item.name }}</B></span>
          <span v-if="item.type === 'app'" class="text-primary"> {{ item.name }}</span>

          <div v-if="item.label" class="ml-4"> <small>&nbsp; {{ item.label }}</small> </div>

        </b-list-group-item>
      </b-list-group>
 
    </div>

  </div>

</template>

<script>

  export default {
    components: {
    },
    data() {
      return {
        schema: [
          {
            name: "Generuj Prompt",
            label: "Wygeneruj zaawansowany prompt",
            type: "app",
            slug: "graphic-prompt-creator-1",
            icon: "tune"
          },
          {
            name: "Prompt ze zdjęcia",
            label: "Wgraj zdjęcie a AI opisze jak je widzi i stworzy prompt",
            type: "app",
            slug: "graphic-prompt-creator-2",
            icon: "image"
          }
        ]
      }
    },
    methods: {

      goto: function(slug) {

        if(slug)
          this.$router.push('/app/' + slug + '/')

      },

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>