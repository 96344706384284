<template> 

  <div class="container pt-4 pb-4">

    <div>

      Wybierz obszar nad którym chcesz pracować:
 
      <b-list-group class="mt-3">
        <b-list-group-item v-for="item in schema" class="pointer" :key="item.name" button @click="goto(item.slug)">
          
          <span class="material-symbols-outlined align-middle text-primary" v-if="item.type === 'app'">{{ item.icon }}</span>

          <span v-if="item.type === 'header'"><B>{{ item.name }}</B></span>
          <span v-if="item.type === 'app'" class="text-primary"> {{ item.name }}</span>

          <div v-if="item.label" class="ml-4"> <small>&nbsp; {{ item.label }}</small> </div>

        </b-list-group-item>
      </b-list-group>
 
    </div>

  </div>

</template>

<script>

  export default {
    components: {
    },
    data() {
      return {
        schema: [
          {
            name: "Zmiana przekonań finansowych",
            type: "app",
            slug: "millionaire-mindset-generator-1",
            icon: "paid"
          },
          {
            name: "Zmiana przekonań osobistych",
            type: "app",
            slug: "millionaire-mindset-generator-2",
            icon: "manage_accounts"
          },
          {
            name: "Wartość godziny czasu pracy",
            type: "app",
            slug: "millionaire-mindset-generator-3",
            icon: "timer"
          },
          {
            name: "Zwiększanie dochodu",
            type: "app",
            slug: "millionaire-mindset-generator-4",
            icon: "show_chart"
          },
          {
            name: "Mierzenie postępów",
            type: "app",
            slug: "millionaire-mindset-generator-5",
            icon: "query_stats"
          },
          {
            name: "Twój Milioner Mentor AI",
            type: "app",
            slug: "millionaire-mindset-generator-6",
            icon: "school"
          },
          {
            name: "Budowanie nawyków milionerów",
            type: "app",
            slug: "millionaire-mindset-generator-7",
            icon: "account_balance"
          },
          {
            name: "Ćwiczenie Milionerów na dziś",
            type: "app",
            slug: "millionaire-mindset-generator-8",
            icon: "calendar_month"
          },
          {
            name: "Pytania coachingowe milionerów",
            type: "app",
            slug: "millionaire-mindset-generator-9",
            icon: "help"
          },
          {
            name: "Medytacja na dziś",
            type: "app",
            slug: "millionaire-mindset-generator-10",
            icon: "self_improvement"
          }
        ]
      }
    },
    methods: {

      goto: function(slug) {

        if(slug)
          this.$router.push('/app/' + slug + '/')

      },

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>