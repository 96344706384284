<template> 

  <div class="container pt-4 mb-5">

    <b-button variant="light" :to="('/app/business-calculator-2/')">
      <span class="material-symbols-outlined align-middle">chevron_left</span>
      Wróć
    </b-button>

    <div>
      
      <b-row align-v="center">

        <b-col lg="12">
 
          <div class="mt-4">

            <div class="mt-3">

              <label>Wpisz ile masz miesięcznie wejść na stronę landing pages? </label>

              <b-input-group>

                <b-form-input v-model="opcja1" size="lg" ref="opcja1" placeholder="np. 10 000"></b-form-input>
                
              </b-input-group>
 
            </div>

            <div class="mt-3">

              <label>Jaką masz konwersję strony przechwytującej</label>

              <b-input-group>

                <b-form-input v-model="opcja2" size="lg" ref="opcja2" placeholder="np. 49 %"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Ile % osób wejdzie na stronę One Time Offer </label>

              <b-input-group>

                <b-form-input v-model="opcja3" size="lg" ref="opcja3" placeholder="np. 90%"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Ile kosztuje produkt w One Time Offer </label>

              <b-input-group>

                <b-form-input v-model="opcja4" size="lg" ref="opcja4" placeholder="np.190 zł"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Jaką masz konwersję na one time offer </label>

              <b-input-group>

                <b-form-input v-model="opcja5" size="lg" ref="opcja5" placeholder="np. 3%"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Ile procent osób przyjdzie na webinar </label>

              <b-input-group>

                <b-form-input v-model="opcja6" size="lg" ref="opcja6" placeholder="np. 40%"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Jaką masz cenę produktu na webinarze  </label>

              <b-input-group>

                <b-form-input v-model="opcja7" size="lg" ref="opcja7" placeholder="np. 1990 zł"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Jaką masz średnią konwersję na webinarze </label>

              <b-input-group>

                <b-form-input v-model="opcja8" size="lg" ref="opcja8" placeholder="np. 10%"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Ile procent osób kupuje z kampanii email  </label>

              <b-input-group>

                <b-form-input v-model="opcja9" size="lg" ref="opcja9" placeholder="np. 6%"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Ile procent kupi produkt ze sprzedaży telefonicznej  </label>

              <b-input-group>

                <b-form-input v-model="opcja10" size="lg" ref="opcja10" placeholder="np. 15%"></b-form-input>

              </b-input-group>

            </div>


          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje treść <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowana treść</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;">

              <div v-if="result" v-html="result" class="pb-3 mb-3" style="border-bottom: 1px solid #ddd;"></div>

            </div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
   
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>
 
    </div>

  </div>

</template>

<script>

  export default {
    name: 'apxc',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        result2: "",
        selectedText: '',
        query: "",
        opcja1: "",
        opcja2: "",
        opcja3: "",
        opcja4: "",
        opcja5: "",
        opcja6: "",
        opcja7: "",
        opcja8: "",
        opcja9: "",
        opcja10: "",
        finishContent: '',
        selectedSchema: null
      }
    },
    methods: {

      generateFinish: function() {

        if(!this.opcja1) return false;
        if(!this.opcja2) return false;
        if(!this.opcja3) return false;
        if(!this.opcja4) return false;
        if(!this.opcja5) return false;
        if(!this.opcja6) return false;
        if(!this.opcja7) return false;
        if(!this.opcja8) return false;
        if(!this.opcja9) return false;
        if(!this.opcja10) return false;

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""


        var query = "Dokonaj wyliczeń w moim biznesie. Miesięcznie wchodzi na moją stronę landing pages z zapisem na webinar "+this.opcja1+" osób. Konwersja mojej strony landing pages z zapisem na webinar to "+this.opcja2+"%. Policz ile osób zapisze się na webinar. Po zapisie na landing pages oferuję jednorazową ofertę w cenie "+this.opcja4+". Na ofertę jednorazową (one time offer ) wchodzi "+this.opcja3+"% osób. konwersja oferty one time offer to "+this.opcja5+"%. Policz ile sprzedam i zarobię na One Time Offer. Na mój webinar przychodzi "+this.opcja6+"% policz liczbę osób, które przyjdą na webinar. Na webinarze sprzedaję produkt za "+this.opcja7+" konwersja na webinarach to "+this.opcja8+"%. Policz ile sprzedam i ile zarobię na webinarze. Po webinarzę do zapisanej listy na webinar organizuję kampanię email. Z kampanii email kupuje "+this.opcja9+"%. Oblicz ile zarobię organizując kampanię email. Po zakończeniu kampanii email organizuję sprzedaż telefoniczną na liście zapisanej na webinar. Konwersja sprzedaży telefonicznej to "+this.opcja10+"%. Wszystko robię na liście zapisanych na webinar. Policz mi ile zarobię  na poszczególnych etapach kampanii? Na każdym etapie odejmuj liczbę osób (poza odejmowanie osób, które kupiły One Time Offer), które już kupiły na wcześniejszym etapie lejka. Ile zarobię w sumie na kampanii z danego ruchu. Napisz co mogę poprawić i zoptymalizować. Oto założenia, że dobre wyniki to konwersja landing pages to 50%, konwersja na One Time Offer to 6%, konwersja w kampanii email to 6% i w kampanii telefonicznej to 15%.";

        query = query + "Używaj znaczników HTML zamiast markdown. Dozwolone znaczniki: Pogrubienie czyli <B></B>, i nowa linia <br/> . Zamiast znaczników nagłówków Hx użyj po prostu pogrubienia.";

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query, 'v': 'o1' }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data
 
          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');


          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowana treść"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },
 
      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Treść została skopiowana do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

    },
    mounted() {

    },
    watch: {

    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
  code { color: #4e82f7 !important; }
</style>