<template> 

  <div class="container pt-4 pb-4">

    <div>

      Wybierz aplikację:
 
      <b-list-group class="mt-3">
        <b-list-group-item v-for="item in schema" class="pointer" :key="item.name" button @click="goto(item.slug)">
          
          <span class="material-symbols-outlined align-middle text-primary" v-if="item.type === 'app'">{{ item.icon }}</span>

          <span v-if="item.type === 'header'"><B>{{ item.name }}</B></span>
          <span v-if="item.type === 'app'" class="text-primary"> {{ item.name }}</span>

          <div v-if="item.label" class="ml-4"> <small>&nbsp; {{ item.label }}</small> </div>

        </b-list-group-item>
      </b-list-group>
 
    </div>

  </div>

</template>

<script>

  export default {
    components: {
    },
    data() {
      return {
        schema: [
          {
            name: "Idealny Klient z AI",
            type: "header"
          },
          {
            name: "Avatar Generator",
            type: "app",
            label: "Aplikacja tworząca awatar klienta",
            slug: "bg-ai-avatar-generator",
            icon: "person"
          },
          {
            name: "Generator Mapy Empatii",
            type: "app",
            label: "Aplikacja wygeneruje Ci mapę empatii Twojego klienta, dzięki czemu lepiej go poznasz",
            slug: "bg-ai-empathy-map-gen",
            icon: "map"
          },
          {
            name: "Generator Niszy",
            type: "app",
            label: "Aplikacja wymyśla Ci nisze, które możesz zagospodarować",
            slug: "bg-ai-niche-gen",
            icon: "precision_manufacturing"
          },
          {
            name: "Ask AI",
            type: "app",
            label: "Poznaj pytania i problemy twoich klientów",
            slug: "bg-ai-ask-ai",
            icon: "contact_support"
          },
          {
            name: "Problem Solve Generator",
            type: "app",
            label: "Aplikacja tworzy zestaw problemów Twojego idealnego klienta",
            slug: "bg-ai-problem-solve-gen",
            icon: "emoji_objects"
          },
          {
            name: "Form Generator AI",
            type: "app",
            label: "Aplikacja AI stworzy Ci listę 20 pytań, które warto zadać swojej społeczności, żeby lepiej poznać grupę docelową",
            slug: "bg-ai-form-generator-ai",
            icon: "fact_check"
          },
          {
            name: "Microproblems Generator AI",
            type: "app",
            label: "Dzięki tej aplikacji poznasz bardzo szczegółowo problemy twojej grupy docelowej",
            slug: "bg-ai-microproblems-gen-ai",
            icon: "bug_report"
          },
          {
            name: "Pain Point Generator",
            type: "app",
            label: "Aplikacja wypisująca problemy i frustracje grupy docelowej",
            slug: "bg-ai-pain-point-gen",
            icon: "location_searching"
          },
          {
            name: "Budowanie zasięgów",
            type: "header"
          },
          {
            name: "Social Media Strategist",
            type: "app",
            label: "Aplikacja AI, która stworzy Ci strategię social media dla twojej firmy",
            slug: "bg-ai-sm-strategist",
            icon: "group"
          },
          {
            name: "Facebook Post Generator",
            type: "app",
            label: "Aplikacja AI pisząca post na Facebooka, które przyciągną Ci nowych fanów",
            slug: "bg-ai-fb-post-gen",
            icon: "post_add"
          },
          {
            name: "Facebook Group Post Generator",
            type: "app",
            label: "Aplikacja AI pisze angażujące posty na grupy na Facebooku",
            slug: "bg-ai-fb-group-post-gen",
            icon: "post_add"
          },
          {
            name: "Instagram Post Generator",
            type: "app",
            label: "Aplikacja AI tworzy atrakcyjne i angażujące posty na Instagram, dostosowane do Twojej grupy docelowej",
            slug: "bg-ai-insta-post-gen",
            icon: "photo_camera"
          },
          {
            name: "Threads Generator",
            type: "app",
            label: "Generuje kreatywne i angażujące treści na Threads, które budują zasięgi i zaangażowanie",
            slug: "bg-ai-threads-gen",
            icon: "gesture"
          },
          {
            name: "Edu Stories Generator",
            type: "app",
            label: "Aplikacja tworzy edukacyjne historie do wykorzystania na mediach społecznościowych, które uczą i inspirują Twoich odbiorców",
            slug: "bg-ai-edu-stories-gen",
            icon: "school"
          },
          {
            name: "Reels Generator",
            type: "app",
            label: "Generuje pomysły na krótkie wideo do Reels a po kliknięciu w pomysł pisze skrypt rolki",
            slug: "bg-ai-reels-gen",
            icon: "movie"
          },
          {
            name: "Faceless Reels Generator",
            type: "app",
            label: "Proponuje pomysły na Reels, które nie wymagają pokazywania twarzy, idealne dla marek lub osób preferujących anonimowość",
            slug: "bg-ai-faceless-reels-gen",
            icon: "movie"
          },
          {
            name: "TikTok Generator",
            type: "app",
            label: "Tworzy pomysły na krótkie wideo na TikToka, które przyciągają uwagę i budują popularność. Po kliknięciu w link AI pisze Ci scenariusz TikToka",
            slug: "bg-ai-tt-gen",
            icon: "live_tv"
          },
          {
            name: "LinkedIn Post Generator",
            type: "app",
            label: "Generuje profesjonalne i angażujące posty na LinkedIn, które pomagają budować markę i nawiązywać relacje biznesowe",
            slug: "bg-ai-linked-post-gen",
            icon: "handshake"
          },
          {
            name: "YouTube Generator AI",
            type: "app",
            label: "Aplikacja AI wymyśli Ci 50 pomysłów na Filmy YouTube na każdy temat",
            slug: "bg-ai-yt-gen-ai",
            icon: "slideshow"
          },
          {
            name: "Article Blog Generator",
            type: "app",
            label: "Aplikacja wymyśla 100 pomysłów na posty na bloga dla słowa kluczowego",
            slug: "bg-ai-article-blog-gen",
            icon: "article"
          },
          {
            name: "Newsletter Generator",
            type: "app",
            label: "Tworzy skuteczne i angażujące newslettery, które zwiększają zaangażowanie twoich subskrybentów",
            slug: "bg-ai-newsletter-gen",
            icon: "mail"
          },
          {
            name: "X Generator",
            type: "app",
            label: "Aplikacja pisze posty na serwis X (dawny Twitter)",
            slug: "bg-ai-x-gen",
            icon: "close"
          },
          {
            name: "Budowanie listy mailingowej",
            type: "header"
          },
          {
            name: "Lead Magnet Generator",
            type: "app",
            label: "Aplikacja wymyśli 50 pomysłów na przyciągający lead magnet",
            slug: "bg-ai-lead-magnet-gen",
            icon: "call"
          },
          {
            name: "Free Raport Generator",
            type: "app",
            label: "Aplikacja napisze darmowy raport",
            slug: "bg-ai-free-raport-gen",
            icon: "clarify"
          },
          {
            name: "Landing Pages Generator",
            type: "app",
            label: "Aplikacja AI Generuje zoptymalizowane strony docelowe, które przyciągają klientów i zwiększają konwersję",
            slug: "bg-ai-landing-pages-gen",
            icon: "newspaper"
          },
          {
            name: "Welcome Email Generator",
            type: "app",
            label: "Aplikacja AI napiszę Ci email powitalny dla subskrybentów newslettera",
            slug: "bg-ai-welcome-email-gen",
            icon: "mail"
          },
          {
            name: "Budowanie  zaangażowanej społeczności",
            type: "header"
          },
          {
            name: "Social Post Generator",
            type: "app",
            label: "Posty budujące zaangażowanie",
            slug: "bg-ai-social-post-gen",
            icon: "post_add"
          },
          {
            name: "Engagement Stories Generator",
            type: "app",
            label: "Aplikacja generując pomysły na relacje budujące zaangażowanie",
            slug: "bg-ai-engagement-stories-gen",
            icon: "auto_stories"
          },
          {
            name: "Comment Generator AI",
            type: "app",
            label: "Aplikacja odpisująca na komentarze",
            slug: "bg-ai-comment-gen-ai",
            icon: "chat"
          },
          {
            name: "Respond Generator",
            type: "app",
            label: "Aplikacja odpisująca na e-maile i wiadomości",
            slug: "bg-ai-respond-gen",
            icon: "reply"
          },
          {
            name: "Question Stories Generator",
            type: "app",
            label: "Aplikacja generujące relacje na Instagramie z pytaniami do społeczności",
            slug: "bg-ai-question-stories-gen",
            icon: "live_help"
          },
          {
            name: "Stwórz bestsellerowy produkt",
            type: "header"
          },
          {
            name: "Product Generator",
            type: "app",
            label: "Aplikacja wymyśli Ci gotowe pomysły na produkty lub usługi",
            slug: "bg-ai-product-gen",
            icon: "inventory_2"
          },
          {
            name: "Bussines Model Generator",
            type: "app",
            label: "Generator modeli biznesowych",
            slug: "bg-ai-bussines-model-gen",
            icon: "conversion_path"
          },
          {
            name: "Innovation Generator",
            type: "app",
            label: "Generator innowacji",
            slug: "bg-ai-innovation-gen",
            icon: "emoji_objects"
          },
          {
            name: "Name Product Generator",
            type: "app",
            label: "Aplikacja wymyśla nazwy produktów",
            slug: "bg-ai-name-product-gen",
            icon: "settings"
          },
          {
            name: "Market Analizer AI",
            type: "app",
            label: "Aplikacja dokona analizy rynku i konkurencji",
            slug: "bg-ai-market-analizer-ai",
            icon: "show_chart"
          },
          {
            name: "USP Generator",
            type: "app",
            label: "Aplikacja wymyśli unikalną cechę produktu",
            slug: "bg-ai-usp-gen",
            icon: "psychology"
          },
          {
            name: "Generator sprzedaży",
            type: "header"
          },
          {
            name: "Sell Idea Generator",
            type: "app",
            label: "Aplikacja wymyśli 50 pomysłów na sprzedanie twojej usługi lub produktu",
            slug: "bg-ai-sell-idea-gen",
            icon: "shopping_basket"
          },
          {
            name: "Selling Post Generator",
            type: "app",
            label: "Aplikacja stworzy Ci posty sprzedażowe dla twojego produkty",
            slug: "bg-ai-selling-post-gen",
            icon: "sell"
          },
          {
            name: "Offer Generator",
            type: "app",
            label: "Aplikacja AI napisze Ci ofertę sprzedażową",
            slug: "bg-ai-offer-gen",
            icon: "storefront"
          },
          {
            name: "Marketing Planner Generator",
            type: "app",
            label: "Aplikacja tworzy plan marketingowy do sprzedaży twojego produktu",
            slug: "bg-ai-marketing-planner-gen",
            icon: "map"
          },
          {
            name: "Facebook Ads Generator",
            type: "app",
            label: "Aplikacja stworzy Ci reklamy na Facebooka",
            slug: "bg-ai-facebook-ads-gen",
            icon: "ads_click"
          },
          {
            name: "E-mail Selling Generator",
            type: "app",
            label: "Generator e-malii sprzedażowych",
            slug: "bg-ai-email-selling-gen",
            icon: "mail"
          },
          {
            name: "Phone Script Generator",
            type: "app",
            label: "Generator skryptu sprzedażowego dla handlowca",
            slug: "bg-ai-phone-script-gen",
            icon: "call"
          },
          {
            name: "Skalowanie i delegowanie",
            type: "header"
          },
          {
            name: "Scaling Bussines AI",
            type: "app",
            label: "Aplikacja tworząca plan skalowania biznesu",
            slug: "bg-ai-scaling-bussines-ai",
            icon: "zoom_in"
          },
          {
            name: "Delegation AI",
            type: "app",
            label: "Aplikacja pomaga znaleźć osoby do delegowania zadań",
            slug: "bg-ai-delegation-ai",
            icon: "waving_hand"
          },
          {
            name: "LTV Generator",
            type: "app",
            label: "Aplikacja planująca ścieżkę klienta",
            slug: "bg-ai-ltv-gen",
            icon: "coffee_maker"
          },
          {
            name: "Product Ladder Generator",
            type: "app",
            label: "Aplikacja wymyślająca drabinę produktów",
            slug: "bg-ai-product-ladder-gen",
            icon: "precision_manufacturing"
          },
          {
            name: "Opti Budget AI",
            type: "app",
            label: "Aplikacja pomagające obniżyć koszty działalności firmy",
            slug: "bg-ai-opti-budget-ai",
            icon: "paid"
          }
        ]
      }
    },
    methods: {

      goto: function(slug) {

        if(slug)
          this.$router.push('/app/' + slug + '/')

      },

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>