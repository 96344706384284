<template> 

  <div class="container pt-4">
 
    <div>
      
      <b-row align-v="center">

        <b-col lg="12">

           
          <div class="mt-4">

            <div>

              <label>Wpisz jaki tekst ma napisać AI:</label>
              
              <b-input-group>
          
                <b-form-input v-model="topic" ref="topic" placeholder="np. mailing, ofertę opis produktu do sklepu internetowego, post sprzedażowy na Facebooka"></b-form-input>
                
              </b-input-group>

            </div>

              
            <div class="mt-3">

              <label>Wybierz copywritera:</label>

              <b-input-group>

                <b-form-select v-model="celebrite" ref="celebrite" :options="this.celebrites"></b-form-select>
                
              </b-input-group>

            </div>


            <div class="mt-3">

              <label>Opisz krótko produkt lub usługę którą ai ma opisać:</label>

              <b-input-group>

                <b-form-input v-model="produkt" ref="produkt" placeholder="np. kurs instagram marketing, donica drewniana do ogrodu"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Kto jest odbiorcą tekstu:</label>

              <b-input-group>

                <b-form-input v-model="odbiorca" ref="odbiorca" placeholder="np. kobiety w wieku 20-30 lat pracujące w korporacjach"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Wpisz dodatkowe wytyczne do tekstu:</label>

              <b-input-group>

                <b-form-input v-model="wytyczne" ref="wytyczne" placeholder="np. tekst ma dotyczyć promocji na Black Friday, opisz warunki promocji"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Wybierz ton:</label>

              <b-input-group>

                <b-form-select v-model="ton" ref="ton" :options="this.tony"></b-form-select>

              </b-input-group>

            </div>


          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje tekst dla Ciebie <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowany tekst</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
  
  
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>

  export default {
    name: 'AppCelebrityPostWriterAi',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        topic: "",
        produkt: "",
        wytyczne: "",
        odbiorca: "",
        ton: "agresywnie sprzedażowy",
        tony: [
          "agresywnie sprzedażowy",
          "profesjonalny",
          "inspirujący",
          "pełen humoru",
          "agresywnie sprzedażowy",
          "motywacyjny",
          "perswazyjny",
          "oparty o NLP",
          "edukacyjny"
        ],
        celebrite: "(BRAK)",
        celebrites: [
          "(BRAK)",
          "David Ogilvy",
          "Eugene Schwartz",
          "Claude Hopkins",
          "John Caples",
          "Robert Collier",
          "Victor Schwab",
          "Gary Halbert",
          "Rosser Reeves",
          "Joseph Sugarman",
          "David Garfinkel",
          "Dan Kennedy",
          "Frank Kern",
          "Joanna Wiebe",
          "Ann Handley",
          "Seth Godin",
          "Russell Brunson",
          "Brian Clark",
          "Ray Edwards",
          "Bob Bly",
          "Donald Miller",
          "Marie Forleo",
          "Amy Porterfield",
          "Neville Medhora",
          "Talia Wolf",
          "Ash Ambirge",
          "Laura Belgray",
          "Tina Lorenz",
          "Kevin Rogers",
          "Liz Willits",
          "Andre Chaperon"
        ],
        finishContent: '',
        selectedSchema: null,
         
      }
    },
    methods: {

      selectSchema: function(item) {

        this.selectedSchema = item
      },

      generateFinish: function() {

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "Napisz [WSTAW CO MA NAPISAĆ] w stylu [WSTAW COPYWRITERA], uwzględniając jego charakterystyczne podejście, takie jak [specyficzne cechy stylu, np. storytelling, USP, emocjonalne odwołanie].\n" +
            "Tekst ma sprzedawać [WSTAW PRODUKT]. Oto dodatkowe informacje o szczegółach oferty [WSTAW DODATKOWE SZCZEGÓŁY OFERTY]\n" +
            "Tekst powinien:\n" +
            "1.\tByć skierowany do [WSTAW GRUPĘ DOCELOWĄ] z jasnym zrozumieniem ich potrzeb i bólu.\n" +
            "2.\tRozpoczynać się chwytliwym nagłówkiem w stylu [WSTAW COPYWRITERA]\n" +
            "3.\tZawierać [emocjonalny storytelling, konkretne przykłady, USP, wezwanie do działania].\n" +
            "4.\tByć skonstruowany w taki sposób, aby prowadził czytelnika krok po kroku do decyzji o zakupie [WSTAW PRODUKT].\n" +
            "Treść powinna być:\n" +
            "•\tJasna i przekonująca,\n" +
            "•\tOparta na [np. dowodach społecznych, emocjach, technikach perswazji],\n" +
            "•\tDostosowana do kanału komunikacji [WSTAW CO MA NAPISAĆ]\n" +
            "Przykładowy ton [WSTAW TON]\n";
 
        if(this.$refs.celebrite)  
          query = query.replaceAll('[WSTAW COPYWRITERA]', this.$refs.celebrite.value)

        if(this.$refs.topic)
          query = query.replaceAll('[WSTAW CO MA NAPISAĆ]', this.$refs.topic.value)

        if(this.$refs.produkt)
          query = query.replaceAll('[WSTAW PRODUKT]', this.$refs.produkt.value)

        if(this.$refs.wytyczne)
          query = query.replaceAll('[WSTAW DODATKOWE SZCZEGÓŁY OFERTY]', this.$refs.wytyczne.value)

        if(this.$refs.odbiorca)
          query = query.replaceAll('[WSTAW GRUPĘ DOCELOWĄ]', this.$refs.odbiorca.value)

        if(this.$refs.ton)
          query = query.replaceAll('[WSTAW TON]', this.$refs.ton.value)


        query = query + "Używaj znaczników HTML zamiast markdown. Dozwolone znaczniki: Pogrubienie czyli <B></B>, i nowa linia <br/> . Zamiast znaczników nagłówków Hx użyj po prostu pogrubienia.";

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query, 'v': 'o1' }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data

          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowany tekst"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },

      loadFromExample: function(keyword) {

        this.topic = keyword

      },

      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Post został skopiowany do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>